import { Box, Container, Grid, Link } from '@mui/material'
import React from 'react'

const dataFooter = [
    {
        title: 'Productos',
        links: [
            {
                name: 'Línea Hospitalaria',
                href: '/#Productos'
            },
            {
                name: 'Línea Farmaceutica',
                href: '/#Productos'
            },
            {
                name: 'Productos Médicos',
                href: '/#Productos'
            },
        ]
    },
    {
        title: 'Contacto',
        links: [
            {
                name: 'Teléfono (0341) 399-9900',
                href: '/#Contacto'
            },
            {
                name: 'Email: Info@lab-sertex.com.ar',
                href: 'mailto:Info@lab-sertex.com.ar'
            },
            {
                name: 'Dirección: Calle 1910 Nº 4535,Rosario, Santa Fe',
                href: 'https://goo.gl/maps/ZGLsg62PH1rMXbYSA'
            },
        ]
    },
]

const Footer = () => {
    return (
        <Box bgcolor="primary.main" color="white" py={5}>
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                        <Box
                            sx={{
                                display: 'flex',
                                boxSizing: 'border-box',
                                width: '70%',
                                height: '90px',
                            }}>

                            <img src='/assets/logos/logohorizontal.png' alt="perrito" style={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                            }} />
                        </Box>

                    </Grid>
                    {dataFooter.map((item, index) => (
                        <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }} key={index}>
                            <Box borderBottom={1}> {item.title} </Box>
                            {item.links.map((link, index) => (
                                <Box key={index} >
                                    <Link href={`${link.href}`} color={'inherit'} underline={'none'}> {link.name} </Link>
                                </Box>
                            ))}

                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 5 }}>
                    <Box sx={{ textAlign: 'center' }}> © 2022 Laboratorio Sertex. Todos los derechos reservados.</Box>
                </Box>



            </Container>
        </Box>
    )
}

export default Footer