import { Routes, Route, BrowserRouter } from 'react-router-dom';
import * as React from 'react';
import Loading from './components/Loading/Loading';
import './App.css';
import Footer from './components/Footer/Footer';



const Landing = React.lazy(() => import('./components/pages/Landing'));
const CardProduct = React.lazy(() => import('./components/CardProduct/CardProduct')); 
const NavBar = React.lazy(() => import('./components/NavBar/NavBar'));
const TeamPage = React.lazy(() => import('./components/pages/Team'));

function App() {
  return (


    <BrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <NavBar />
        <Routes>
          <Route exat path="/" element={<Landing />} />
          <Route exat path="/productos/:product" element={<CardProduct />} />
          <Route exat path="/teamSertex" element={<TeamPage />} />
        </Routes>
        <Footer />
      </React.Suspense>
    </BrowserRouter>

  );
}

export default App;
